import React from 'react'
import { graphql } from 'gatsby';

import { Layout, Seo } from '../components/commons'
import {
  RallyHeader,
  RallyEditionPresentation,
  RallyEditionVideos,
  RallyEditionPhotos,
  RallyEditionStages, RallyEditionPartners, RallyEditionPress
} from '../components/rally'
import Section from '../components/sections'
import { Wrapper } from '../atoms'

const RallyEditionPage = ({ data }) => {
  const { datoCmsEdition } = data

  const edition = {
    name: datoCmsEdition.name,
    editionDates: datoCmsEdition.editionDates,
    description: datoCmsEdition.description,
    rallyPlate: datoCmsEdition.rallyPlate,
    poster: datoCmsEdition.poster,
    primaryColor: datoCmsEdition.rally.primaryColor
  }

  const videos = {
    videosHeadline: datoCmsEdition.videosHeadline,
    videos: datoCmsEdition.videos,
    primaryColor: datoCmsEdition.rally.primaryColor,
    rallySlug: datoCmsEdition.rally.slug
  }

  const photos = {
    photosHeadline: datoCmsEdition.photosHeadline,
    photosGallery: datoCmsEdition.photosGallery,
    primaryColor: datoCmsEdition.rally.primaryColor,
    rallySlug: datoCmsEdition.rally.slug
  }

  const stages = {
    stagesHeadline: datoCmsEdition.stagesHeadline,
    stagesImage: datoCmsEdition.stagesImage,
    stagesCities: datoCmsEdition.stagesCities,
    rallySlug: datoCmsEdition.rally.slug
  }

  const partners = {
    partnersHeadline: datoCmsEdition.partnersHeadline,
    partnersTypes: datoCmsEdition.partnersTypes,
    primaryColor: datoCmsEdition.rally.primaryColor,
  }

  const press = {
    pressHeadline: datoCmsEdition.pressHeadline,
    pressPosts: datoCmsEdition.pressPosts,
    rallySlug: datoCmsEdition.rally.slug,
    primaryColor: datoCmsEdition.rally.primaryColor,
  }

  return (
    <Layout>
      <Seo content={datoCmsEdition.seo} />
      <Wrapper>
        <RallyHeader rally={datoCmsEdition.rally}/>

        <RallyEditionPresentation content={edition} />

        {videos.videos && videos.videos.length > 0 && (
          <RallyEditionVideos content={videos} />
        )}

        {photos.photosGallery && photos.photosGallery.length > 0 && (
          <RallyEditionPhotos content={photos} />
        )}

        {stages.stagesCities && stages.stagesCities.length > 0 && (
          <RallyEditionStages content={stages} />
        )}

        {partners.partnersTypes && partners.partnersTypes.length > 0 && (
          <RallyEditionPartners content={partners} />
        )}

        {press.pressPosts && press.pressPosts.length > 0 && (
          <RallyEditionPress content={press} />
        )}

        {datoCmsEdition.rally.incomingEdition && (
          <Section sectionId={'incoming_edition'} content={datoCmsEdition.rally} />
        )}
      </Wrapper>
    </Layout>
  )
}

export default RallyEditionPage

export const query = graphql`
  query RallyEdition($rallySlug: String!, $editionSlug: String!) {
    datoCmsEdition(slug: {eq: $editionSlug}, rally: {slug: {eq: $rallySlug}}) {
      seo {
        description
        title
        twitterCard
        image {
          url
        }
      }
      rally {
        ...rallyFragment
      }
      slug
      name
      videosHeadline
      videos {
        videoUrl
        video {
          width
          height
          provider
          url
        }
        label
        id
      }
      stagesImage {
        alt
        url
        gatsbyImageData(
          width: 800
          placeholder: NONE
        )
      }
      stagesHeadline
      stagesCities {
        id
        city
      }
      rallyPlate {
        alt
        url
        gatsbyImageData(
          width: 200
          placeholder: NONE
        )
      }
      poster {
        alt
        url
        gatsbyImageData(
          width: 1000
          placeholder: NONE
        )
      }
      photosHeadline
      photosGallery {
        alt
        url
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      partnersTypes {
        label
        id
        partners {
          id
          url
          name
          logo {
            alt
            url
            gatsbyImageData(
              width: 160
              placeholder: NONE
            )
          }
        }
      }
      partnersHeadline
      pressHeadline
      pressPosts {
        id
        name
        source
        document {
          url
        }
        articleLink
      }
      editionDates
      description
    }
  }
`
